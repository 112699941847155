.gameplay_section {
  min-height: 100vh;
  background: black;
}

.gameplay_section_video {
  width: 100%;
  height: calc(100vh - 10rem);
  overflow: hidden;
  background-image: url("../../Assets/images/video_placeholder.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.gameplay_section_video video {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
  display: block;
}

.gameplay_section_video_text {
  z-index: 12;
  position: absolute;
  height: calc(100vh - 10rem);
  background: linear-gradient(180deg, rgb(0, 0, 0) 25%, rgba(0, 0, 0, 0) 86%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gameplay_section_video_text_heading {
  font-size: 4rem;
}

.gameplay_section_video_text_span {
  color: #efe2cd;
  font-size: 1.6rem;
  position: relative;
  text-align: center;
  max-width: 70rem;
}

.gameplay_section_video_text_span::before {
  content: "";
  position: absolute;
  bottom: -20px;
  height: 3px;
  background: linear-gradient(
    87deg,
    rgba(0, 0, 0, 0) 15%,
    rgb(183, 145, 85) 50%,
    rgba(0, 0, 0, 0) 85%
  );
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

.gameplay_section_container {
  background: linear-gradient(
    180deg,
    rgb(0, 0, 0) 35%,
    rgba(0, 0, 0, 0) 68%,
    rgb(0, 0, 0) 95%
  );
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 10rem;
}

.gameplay_section_container_wrapper_about,
.gameplay_section_container_wrapper_currency {
  padding-top: 5rem;
  display: flex;
  gap: 20px;
  align-items: center;
}

.gameplay_section_container_wrapper_about_image,
.gameplay_section_container_wrapper_currency_image {
  width: 30%;
  height: 22rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gameplay_section_container_wrapper_about_image img,
.gameplay_section_container_wrapper_currency_image img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.gameplay_section_container_wrapper_about_text,
.gameplay_section_container_wrapper_currency_text {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.gameplay_section_container_wrapper_currency_text img {
  width: 100%;
  object-fit: cover;
}

.gameplay_section_container_wrapper_about_text_heading,
.gameplay_section_container_wrapper_currency_text_heading {
  color: #b79155;
  font-size: 4rem;
  font-family: var(--font), serif;
  position: relative;
  margin-bottom: 10px !important;
}

.gameplay_section_container_wrapper_about_text_heading::before,
.gameplay_section_container_wrapper_currency_text_heading::before {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 50%;
  left: -2px;
  height: 2px;
  background: linear-gradient(to top, #b79155 40%, #efe2cd);
  transform: skew(120deg);
}

.gameplay_section_container_wrapper_about_text_span,
.gameplay_section_container_wrapper_currency_text_span {
  font-size: 1.4rem;
  color: #ffffff9a;
  position: relative;
}

/* .gameplay_section_container_wrapper_about_text_span::before,
.gameplay_section_container_wrapper_currency_text_span::before {
  content: "";
  position: absolute;
  bottom: -10px;
  width: 40%;
  right: 0;
  height: 2px;
  background: white;
  transform: skew(120deg);
} */

.gameplay_section_video_text_image {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 999;
}

.currency_container {
  padding: 40px 0;
  /* min-height: 100vh; */
  display: flex;
  gap: 120px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.currency_card {
  width: 30rem;
  height: 30rem;
  background: black;
  padding: 2rem;
  border-radius: 0px;
  position: relative;
}

.currency_card_name {
  font-size: 2.4rem;
  font-family: var(--font), serif;
  color: #fff;
}

.currency_card_type {
  font-size: 1.2rem;
  color: #d5d5d5;
}

.currency_card_type span {
  font-size: 1.4rem;
  font-weight: bold;
}

.currency_card_desc {
  font-size: 1.4rem;
  margin-top: 20px !important;
  width: 80%;
  z-index: 99;
  position: relative;
}

.currency_card_image {
  position: absolute;
  bottom: -30px;
  right: -100px;
  height: 200px;
  width: 200px;
  transform: rotate(20deg);
}

.currency_card_image img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.currency_card1 {
  border: 1px solid #9d06e8;
  background: linear-gradient(146deg, rgb(0, 0, 0) 43%, rgb(157, 6, 232) 100%);
}

.currency_card_desc1 {
  color: #9d06e8;
}

.currency_card2 {
  border: 1px solid #44a6ff;
  background: linear-gradient(146deg, rgb(0, 0, 0) 43%, rgb(68, 166, 255) 100%);
}

.currency_card_desc2 {
  color: #44a6ff;
}

.currency_card3 {
  border: 1px solid #0b8646;
  background: linear-gradient(146deg, rgb(0, 0, 0) 43%, #0b8646 100%);
}

.currency_card_desc3 {
  color: #0b8646;
}

.gameplay_video1,
.gameplay_video2 {
  width: 100%;
  aspect-ratio: 16/9;
  background-image: url("../../Assets/images/video_placeholder (2).png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.gameplay_video1 video,
.gameplay_video2 video {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
  display: block;
}

.video_overlay1,
.video_overlay2 {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.633);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
  opacity: 1;
}

.video_overlay1 p,
.video_overlay2 p {
  margin-top: 20px !important;
  color: #fff;
  font-size: 1.4rem;
  font-family: var(--font), serif;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #b79155;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 120px;
    height: 120px;
    opacity: 0;
  }
}
.bottom_carousel {
  background: rgb(0, 0, 0);
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0;
}
.bottom_carousel_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.bottom_carousel_heading p {
  font-size: 4rem;
  text-transform: uppercase;
  text-align: center;
}
.bottom_carousel_heading span {
  color: #ffffff9a;
  font-size: 1.6rem;
  text-align: center !important;
  max-width: 50%;
}
.bottom_carousel_buttons {
  display: flex;
  gap: 50px;
  justify-content: center;
}
.bottom_carousel_buttons_item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.bottom_carousel_buttons_item p {
  color: #fff;
  text-shadow: #b79155 0px 0 5px;
  font-size: 1.5rem;
}
.bottom_carousel_buttons_item button {
  height: 100px;
  width: 100px;
  border-radius: 500px;
  outline: none;
  border: none;
  overflow: hidden;
  border: 2px solid white;
  box-shadow: 0 0 20px #b79155;
}
.bottom_carousel_buttons_item button:hover {
  box-shadow: 0 0 20px #b79155 !important;
}
.bottom_carousel_buttons_item button img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  filter: saturate(0.5);
  transition: 0.2s ease;
}
.bottom_carousel_buttons_item button img:hover {
  filter: saturate(1);
  transform: scale(1.1);
}
.bottom_carousel_carousel {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 80vw;
}
.bottom_carousel_carousel_prev button {
  height: 60px;
  width: 60px;
  border-radius: 500px;
  box-shadow: 0 0 5px #b79155;
  background: none;
  border: 2px solid #b79155;
  transition: all 0.5s ease;
}
.bottom_carousel_carousel_prev button:hover {
  box-shadow: inset 0 0 20px #b79155;
}
.bottom_carousel_carousel_prev button i {
  color: #b79155;
  font-size: 3rem;
  font-weight: bold;
}
.bottom_carousel_carousel_next button {
  height: 60px;
  width: 60px;
  border-radius: 500px;
  box-shadow: 0 0 5px #b79155;
  background: none;
  border: 2px solid #b79155;
  transition: all 0.5s ease;
}
.bottom_carousel_carousel_next button:hover {
  box-shadow: inset 0 0 20px #b79155;
}
.bottom_carousel_carousel_next button i {
  color: #b79155;
  font-size: 3rem;
  font-weight: bold;
}
.bottom_carousel_carousel_image {
  width: 90%;
  height: 70vh;
  overflow: hidden;
}
.bottom_carousel_carousel_image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.bottom_carousel_details {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottom_carousel_details p {
  font-size: 3rem;
}
.bottom_carousel_details span {
  color: #ffffff9a;
  font-size: 1.6rem;
  max-width: 50%;
  text-align: center;
}
.bottom_carousel_navigation_buttons {
  margin-top: 40px;
  display: flex;
  gap: 10px;
}
.bottom_carousel_navigation_buttons button {
  width: 30px;
  height: 5px;
  border-radius: 10px;
  outline: none;
  border: none;
  transition: 0.2s ease;
}
.bottom_carousel_navigation_buttons button:hover {
  box-shadow: 0 0 10px #b79155;
} /*# sourceMappingURL=gameplay.css.map */

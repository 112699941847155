.contact_us {
  display: flex;
  flex-direction: column;
}
.contact_us_container {
  height: calc(100vh - 10rem);
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
  align-items: center;
  background-image: linear-gradient(
      180deg,
      rgb(0, 0, 0) 5%,
      rgba(0, 0, 0, 0.3702731092) 50%,
      rgb(0, 0, 0) 95%
    ),
    url(../../Assets/images/tanari_jungle.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.contact_us_container p {
  font-size: 4rem;
}
.contact_us_container span {
  color: #efe2cd;
  font-size: 1.6rem;
  position: relative;
  text-align: center;
}
.contact_us_container span::before {
  content: "";
  position: absolute;
  bottom: -20px;
  height: 3px;
  background: linear-gradient(
    87deg,
    rgba(0, 0, 0, 0) 15%,
    rgb(183, 145, 85) 50%,
    rgba(0, 0, 0, 0) 85%
  );
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}
.contact_us_links {
  margin-top: 12rem;
  position: relative;
}
.contact_us_links ul {
  display: flex;
  gap: 20px;
  list-style: none;
}
.contact_us_links a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  background: #000000;
  padding: 10px 40px;
  border-radius: 0px;
  backdrop-filter: blur(10px);
  transition: 0.3s;
}
.contact_us_links a:hover {
  background: #fff;
  transform: translateY(-10px);
}
.contact_us_links a:hover i {
  color: #000;
  transform: translateY(-30px) scale(1.5) rotate(-10deg);
}
.contact_us_links a:hover p {
  color: #000;
  font-weight: bold;
}
.contact_us_links a i {
  color: #b79155;
  font-size: 4rem;
  transition: 0.3s;
}
.contact_us_links a p {
  font-size: 1.6rem;
}

.footer_download_contact {
  background: linear-gradient(180deg, rgb(0, 0, 0) 30%, rgb(183, 145, 85) 100%);
  padding: 3px;
}
.footer_download_contact_container {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background-color: black;
}
.footer_download_contact_image {
  width: 30rem;
  height: 30rem;
}
.footer_download_contact_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.footer_download_contact_btn {
  width: 20rem;
  height: 6rem;
}
.footer_download_contact_btn button {
  font-size: 2rem;
}

.media_container {
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  align-items: center;
  height: max-content;
  background-image: linear-gradient(
      180deg,
      rgb(0, 0, 0) 5%,
      rgba(0, 0, 0, 0.3702731092) 50%,
      rgb(0, 0, 0) 95%
    ),
    url(../../Assets/images/ashen_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.media_container_heading {
  font-size: 4rem;
}
.media_container_span {
  color: #efe2cd;
  font-size: 1.6rem;
  position: relative;
  text-align: center;
  max-width: 70rem;
}
.media_container_span::before {
  content: "";
  position: absolute;
  bottom: -20px;
  height: 3px;
  background: linear-gradient(
    87deg,
    rgba(0, 0, 0, 0) 15%,
    rgb(183, 145, 85) 50%,
    rgba(0, 0, 0, 0) 85%
  );
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

.featured_articles {
  margin-top: 6rem;
}
.featured_articles_heading {
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
}
.featured_articles_container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.featured_article {
  background: black;
  border-radius: 0px;
  display: flex;
  min-height: 30rem;
}
.featured_article_gradient_outline {
  background: linear-gradient(0deg, rgb(0, 0, 0) 70%, rgb(183, 145, 85) 100%);
  padding: 2px;
  border-radius: 0px;
}
.featured_article_content {
  width: 50%;
  padding: 30px;
}
.featured_article_content_heading {
  font-size: 2.5rem;
  color: #fff;
}
.featured_article_content_paragraph {
  color: #efe2cd;
  font-size: 1.3rem;
  margin-bottom: 3rem !important;
}
.featured_article_content a {
  color: #b79155;
  text-decoration: underline;
  font-size: 1.6rem;
}
.featured_article_image {
  width: 50%;
  min-height: 30rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  position: relative;
}
.featured_article_image::before {
  position: absolute;
  content: "";
  inset: 0;
  background-image: linear-gradient(
    90deg,
    rgb(0, 0, 0) 4%,
    rgba(0, 0, 0, 0) 38%
  );
}
.featured_article_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.all_articles {
  margin-top: 4rem;
}
.all_articles_heading {
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
}
.all_articles_container {
  margin-top: 10px;
  display: flex;
  gap: 20px;
  min-height: 40rem;
  flex-wrap: wrap;
  align-items: center;
}

.article {
  background: black;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  height: 40rem;
}
.article_gradient_outline {
  width: 30%;
  min-width: 30rem;
  background: linear-gradient(0deg, rgb(0, 0, 0) 70%, rgb(183, 145, 85) 100%);
  padding: 2px;
  border-radius: 0px;
}
.article_content {
  width: 100%;
  min-height: 50%;
  padding: 20px;
}
.article_content_heading {
  font-size: 1.6rem;
  color: #fff;
}
.article_content_paragraph {
  color: #efe2cd;
  font-size: 1.2rem;
  margin-bottom: 3rem;
}
.article_content a {
  color: #b79155;
  text-decoration: underline;
  font-size: 1.6rem;
}
.article_image {
  max-width: 50rem;
  height: 100%;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  overflow: hidden;
  position: relative;
}
.article_image::before {
  position: absolute;
  content: "";
  inset: 0;
  background-image: linear-gradient(
    180deg,
    rgb(0, 0, 0) 4%,
    rgba(0, 0, 0, 0) 85%
  );
}
.article_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer_download_media {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgb(0, 0, 0) 3%,
    rgb(0, 0, 0) 28%,
    rgb(183, 145, 85) 84%
  );
  padding: 3px;
}
.footer_download_media_container {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background: linear-gradient(0deg, rgb(0, 0, 0) 87%, rgba(0, 0, 0, 0) 100%);
}
.footer_download_media_image {
  width: 30rem;
  height: 30rem;
}
.footer_download_media_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.footer_download_media_btn {
  width: 20rem;
  height: 6rem;
}
.footer_download_media_btn button {
  font-size: 2rem;
}

.about_container {
  min-height: calc(100vh - 10rem);
  padding: 2rem;
  padding-top: 5rem;
  background-image: linear-gradient(
      180deg,
      rgb(0, 0, 0) 11%,
      rgba(0, 0, 0, 0.3702731092) 56%,
      rgb(0, 0, 0) 95%
    ),
    url("../../Assets/images/Haunted_Mire.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about_container_heading {
  font-size: 4rem;
}
.about_container_span {
  color: #efe2cd;
  font-size: 1.6rem;
  max-width: 900px;
  text-align: center;
  position: relative;
}
.about_container_span::before {
  content: "";
  position: absolute;
  bottom: -20px;
  height: 3px;
  background: linear-gradient(
    87deg,
    rgba(0, 0, 0, 0) 15%,
    rgb(183, 145, 85) 50%,
    rgba(0, 0, 0, 0) 85%
  );
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

.cards_container {
  margin-top: 10rem;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.team_card_main {
  height: 26rem;
  width: 18rem;
  position: relative;
  border-radius: 0px;
}

.team_card {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d !important;
  transition: all 0.5s ease;
  border-radius: 0px;
  animation: flip 1s linear;
}
@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
.team_card:hover {
  transform: rotateY(180deg);
}
.team_card_front {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(11, 11, 11);
  backface-visibility: hidden;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.team_card_front span {
  font-size: 1.2rem;
  color: #b79155;
}
.team_card_front p {
  font-size: 1.4rem;
  color: #ffffff9a;
}
.team_card_front_image {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9921218487) 36%,
    rgb(183, 145, 85) 100%
  );
  padding: 3px;
  margin-bottom: 10px;
}
.team_card_front_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.team_card_back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #b79155;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  border-radius: 0px;
  padding: 20px;
}
.team_card_back_bottom_image {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
}
.team_card_back_bottom_image img {
  width: 100%;
}
.team_card_back p {
  font-size: 1.6rem;
  color: black;
  font-weight: bold;
}
.team_card_back span {
  font-size: 1.2rem;
  color: #fff;
}
.team_card_back ul {
  margin-top: 10px;
}
.team_card_back ul li p {
  font-size: 1.2rem;
  font-weight: 400;
}

.other_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.studio_members,
.outsourced_members {
  background-color: rgb(11, 11, 11);
  margin-top: 5rem;
  padding: 20px;
  border-radius: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
}
.studio_members_image,
.outsourced_members_image {
  width: 12rem;
  height: 12rem;
  display: flex;
  justify-content: center;
}
.studio_members_image img,
.outsourced_members_image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.studio_members span,
.outsourced_members span {
  font-size: 1.2rem;
  color: #b79155;
  margin-top: 10px;
  text-align: center;
}
.studio_members_members,
.outsourced_members_members {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.studio_members_members p,
.outsourced_members_members p {
  font-size: 1.6rem;
  color: #ffffff9a;
}

.studio_members_members {
  gap: 5px !important;
}

.outsourced_members {
  margin-top: 20px !important;
}
.outsourced_members_image {
  background-color: #fff;
}

.studio_members_members,
.other_members {
  background-color: rgb(11, 11, 11);
  margin-top: 5rem;
  padding: 20px;
  border-radius: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.studio_members_members_image,
.other_members_image {
  width: 12rem;
  height: 12rem;
  display: flex;
  justify-content: center;
}
.studio_members_members_image img,
.other_members_image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.studio_members_members span,
.other_members span {
  font-size: 1.2rem;
  color: #b79155;
  margin-top: 10px;
}
.studio_members_members_members,
.other_members_members {
  display: flex;
  gap: 10px;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.studio_members_members_members p,
.other_members_members p {
  font-size: 1.6rem;
  color: #ffffff9a;
}

.studio_members_members {
  margin-top: 20px !important;
}

.other_members {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.other_members p {
  font-size: 1.6rem;
  color: #ffffff9a;
}

@media (max-width: 768px) {
  .hero_content_heading {
    line-height: 30px;
    margin-bottom: 10px !important;
  }

  .hero_content_btn {
    width: 15rem;
    height: 4rem;
  }

  .hero_content_btn button {
    font-size: 1.4rem;

    font-family: var(--font), serif;
  }

  .hero_content_newsletter input {
    height: 5rem !important;
  }

  .section_two_content_features {
    margin-top: 5rem;
  }

  .game_features {
    height: max-content;
  }

  .game_features_info_heading,
  .characters_info_heading {
    font-size: 2rem !important;
  }

  .game_features_info span,
  .characters_info span {
    font-size: 1.2rem !important;
  }

  .game_features_carousel {
    width: 100% !important;
  }

  .game_features_info {
    width: 100% !important;
  }

  .characters_image {
    width: 100% !important;
    height: 30rem;
  }

  .characters_image img {
    height: 100%;
    object-fit: contain;
    top: -2rem;
  }

  .footer_download {
    padding: 3px;
  }

  .footer_download_container {
    gap: 20px;
  }

  .footer_download_image {
    width: 20rem;
    height: 20rem;
  }

  .footer_download_btn {
    width: 20rem;
    height: 5rem;
  }

  .footer_download_btn button {
    font-size: 1.6rem;
  }

  .navbar {
    height: 6rem;
  }

  .nav-link:hover {
    color: #b79155 !important;
  }

  .navbar-brand {
    height: 6rem;
  }

  .hero_section {
    height: calc(100vh - 110px);
  }

  .section_two_content_video {
    height: 30rem;
  }

  .gradient_border {
    width: 100% !important;
  }

  .section_two_content_features_block {
    min-height: max-content !important;
  }

  .contact_us_container {
    padding: 2rem;
    padding-top: 2rem;
  }

  .contact_us_container_heading {
    font-size: 2.5rem !important;
  }

  .contact_us_container_span {
    font-size: 1.2rem !important;
  }

  .contact_us_links {
    margin-top: 6rem !important;
  }

  .contact_us_links ul {
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0 !important;
  }

  .contact_us_links a {
    padding: 10px !important;
    height: 60px !important;
    width: 80px !important;
  }

  .contact_us_links a:hover {
    background: #000000 !important;
    transform: translateY(0px) !important;
  }

  .contact_us_links a:hover i {
    color: inherit !important;
    transform: translateY(0px) scale(1) rotate(0deg) !important;
  }

  .contact_us_links a:hover p {
    color: #ffffff !important;
    font-weight: bold !important;
  }

  .contact_us_links a i {
    color: #ffffff !important;
    font-size: 2.5rem !important;
    transition: 0.3s !important;
  }

  .contact_us_links a p {
    font-size: 1rem !important;
    color: #b79155 !important;
  }

  /* about Page */

  .about_container {
    padding-top: 2rem !important;
  }

  .team_card_main {
    width: 12rem !important;
    height: 22rem !important;
  }

  .team_card_front_image {
    width: 10rem !important;
    height: 10rem !important;
  }

  .team_card_front span {
    font-size: 10px !important;
  }

  .team_card_front p {
    font-size: 10px !important;
  }

  .team_card_back {
    padding: 10px !important;
  }

  .team_card_back span {
    font-size: 10px !important;
  }

  .team_card_back p {
    font-size: 10px !important;
  }

  .team_card_back ul li p {
    font-size: 10px !important;
    line-height: 12px;
  }

  .team_card_back ul {
    margin-top: 5px !important;
  }

  .about_container_heading {
    font-size: 2.5rem !important;
  }

  .about_container_span {
    font-size: 1.2rem !important;
  }

  .cards_container {
    margin-top: 5rem !important;
  }

  .other_container {
    grid-template-columns: 1fr !important;
    gap: 20px !important;
  }

  .studio_members span,
  .outsourced_members span {
    font-size: 10px !important;
  }

  .studio_members_image,
  .outsourced_members_image {
    width: 10rem !important;
    height: 10rem !important;
  }

  .studio_members_members p,
  .outsourced_members_members p,
  .other_members p {
    font-size: 1.2rem !important;
  }

  .studio_members,
  .outsourced_members {
    margin-top: 20px !important;
  }

  /* media page */

  .media_container {
    padding: 2rem !important;
    padding-top: 2rem !important;
  }

  .media_container_heading {
    font-size: 2.5rem !important;
  }

  .media_container_span {
    font-size: 1.2rem !important;
  }

  .featured_articles {
    margin-top: 4rem !important;
  }

  .featured_articles_heading,
  .all_articles_heading {
    font-size: 1.2rem !important;
  }

  .featured_article {
    flex-direction: column-reverse;
  }

  .featured_article_content {
    width: 100% !important;
    padding: 15px !important;
  }

  .featured_article_image {
    width: 100% !important;
    min-height: 100px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 11px !important;
  }

  .featured_article_image::before {
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 4%,
      rgba(0, 0, 0, 0) 38%
    ) !important;
  }

  .featured_article_content_heading,
  .article_content_heading {
    font-size: 1.4rem !important;
  }

  .featured_article_content_paragraph,
  .article_content_paragraph {
    font-size: 10px !important;
    font-weight: 399;
  }

  .article_content_paragraph {
    margin-bottom: 10px !important;
  }

  .featured_article_content a,
  .article_content a {
    font-size: 1.2rem !important;
  }

  .all_articles_container {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 10px !important;
  }

  .article_gradient_outline {
    min-width: 100% !important;
    padding: 1px !important;
  }

  .article_content {
    padding: 10px !important;
    z-index: 2;
  }

  .article {
    height: 20rem !important;
  }

  /* gameplay page */

  .gameplay_section_container_wrapper_about,
  .gameplay_section_container_wrapper_currency {
    flex-direction: column;
    padding: 20px;
    padding-top: 5rem;
    gap: 20px !important;
  }

  .gameplay_section_container_wrapper_about_image,
  .gameplay_section_container_wrapper_currency_image {
    width: 28rem !important;
  }

  .gameplay_section_container_wrapper_about_text,
  .gameplay_section_container_wrapper_currency_text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100% !important;
  }

  .gameplay_section_container_wrapper_about_text_heading,
  .gameplay_section_container_wrapper_currency_text_heading {
    line-height: 40px;
  }

  .gameplay_section_container_wrapper_about_text_span {
    font-size: 1.2rem !important;
  }

  .gameplay_section_video {
    height: 40vh !important;
  }

  .gameplay_section_video_text {
    padding: 20px;
    height: 40vh !important;
  }

  .gameplay_section_video_text_heading {
    font-size: 2.5rem !important;
  }

  .gameplay_section_video_text_span {
    font-size: 1.2rem !important;
  }

  .gameplay_section_container_wrapper_about_text_heading::before,
  .gameplay_section_container_wrapper_currency_text_heading::before {
    bottom: -10px !important;
  }

  .currency_container {
    padding: 20px !important;
    gap: 40px !important;
  }

  .currency_card {
    width: 100% !important;
    height: 300px !important;
  }

  .currency_card_image {
    bottom: -20px !important;
    right: 0px !important;
    height: 150px !important;
    width: 150px !important;
  }

  .currency_card_name {
    font-size: 1.6rem !important;
  }

  .currency_card_type {
    font-size: 1rem !important;
  }

  .currency_card_desc {
    font-size: 1.4rem !important;
  }

  .gameplay_section_video_text_image img {
    width: 300px !important;

    object-fit: contain;
  }

  .video_overlay1 p,
  .video_overlay2 p {
    font-size: 1rem !important;
  }

  .about_image {
    height: 50vh !important;
    margin: 3rem 0 !important;
    margin-top: 4rem !important;
  }

  .about_text_heading {
    font-size: 2.5rem !important;
  }

  .about_text_span {
    font-size: 1.2rem !important;
  }

  .bottom_carousel_heading p {
    font-size: 2.5rem !important;
  }

  .bottom_carousel_heading span {
    font-size: 1.2rem !important;
    max-width: 100% !important;
  }

  .bottom_carousel_buttons {
    gap: 20px !important;
    flex-wrap: wrap;
  }

  .bottom_carousel_buttons_item button {
    width: 80px !important;
    height: 80px !important;
  }

  .bottom_carousel_buttons_item p {
    font-size: 1.2rem !important;
  }

  .bottom_carousel_carousel {
    width: 95vw !important;
  }

  .bottom_carousel_carousel_prev button {
    height: 40px !important;
    width: 40px !important;
  }

  .bottom_carousel_carousel_next button {
    height: 40px !important;
    width: 40px !important;
  }

  .bottom_carousel_carousel_prev i {
    font-size: 1.5rem !important;
  }

  .bottom_carousel_carousel_next i {
    font-size: 1.5rem !important;
  }

  .bottom_carousel_carousel_image {
    width: 100% !important;
    height: 150px !important;
    overflow: hidden;
  }

  .bottom_carousel_details {
    margin-top: 20px !important;
  }
  .bottom_carousel_details p {
    font-size: 2rem !important;
  }
  .bottom_carousel_details span {
    font-size: 1.2rem !important;
    max-width: 80% !important;
    text-align: center;
  }

  /* Heroes page */
  .hero_details {
    opacity: 1 !important;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9164915966) 10%,
      rgba(68, 166, 255, 0.0509453782) 100%
    ) !important;
  }

  .hero_details p {
    font-size: 1.2rem !important;
    line-height: 20px !important;
    padding: 5px !important;
  }

  .heroes_heading_image {
    display: none;
  }

  .heroes_heading {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .heroes_heading p {
    font-size: 2.5rem !important;
  }

  .heroes_heading span {
    font-size: 1.2rem !important;
  }

  .search_wrapper {
    width: 100% !important;
  }

  .heroes_container {
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 10px !important;
  }

  .hero_img:hover {
    transform: scale(1) !important;
  }

  /* //hero page */

  .hero_ability {
    flex-direction: column;
    height: max-content !important;
    max-width: 100% !important;
  }

  .hero_ability_left {
    width: 100% !important;
  }

  .hero_ability_left_name {
    font-size: 2.5rem !important;
  }

  .hero_ability_left_ability {
    font-size: 1.2rem !important;
    margin-bottom: 5px !important;
  }

  .hero_ability_left_abilities_details {
    margin-top: 15px !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
  }

  .hero_ability_right {
    width: 100% !important;
    height: 400px !important;
    margin-top: 10px !important;
  }

  .hero_ability_right img {
    height: 100% !important;
  }
  .hero_ability_right video {
    width: 100% !important;
  }
  .hero_ability_left_abilities_details_field {
    gap: 10px;
    padding: 10px !important;
    width: 100% !important;
  }

  .hero_ability_left_abilities_details_field img {
    width: 30px !important;
    height: 30px !important;
  }

  .hero_ability_left_abilities_details_field p {
    font-size: 1.5rem !important;
    margin-bottom: 5px !important;
  }

  .hero_ability_left_abilities_details_field span {
    font-size: 2rem !important;
  }

  .abilities_grid_item_details {
    display: none !important;
  }

  .abilities_grid {
    margin-top: 6rem !important;
  }

  .abilities_grid_heading {
    font-size: 4rem;
    position: relative;
  }

  .abilities_grid_container {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1FR !important;
  }

  .abilities_grid_item {
    width: 80px !important;
    height: 80px !important;
  }

  .video_container {
    flex-direction: column !important;
    width: 90% !important;
    padding: 0 !important;
    height: max-content !important;
  }

  .video_container_video {
    width: 100% !important;
    overflow: hidden;
    height: 200px !important;
  }

  .video_container_details {
    width: 100% !important;
    padding: 20px;
  }

  .video_container_details p {
    font-size: 2.5rem !important;
    line-height: 20px !important;
    position: relative;
  }

  .video_container_details span {
    font-size: 1.2rem !important;
    color: #fff;
  }

  /* changelogs */
  .changelog_desc p {
    font-size: 1rem !important;
    color: #ffffffc7;
    font-weight: 300;
    letter-spacing: 1px;
    padding-right: 0rem !important;
    line-break: anywhere;
  }
  .changelog_container_heading {
    font-size: 2.5rem !important;
  }
}

@media (max-width: 1200px) {
  .hero_details span {
    font-size: 1rem !important;
  }
}

.changelog_container {
  min-height: calc(100vh - 10rem);
  padding: 2rem;
  padding-top: 5rem;
  background-image: linear-gradient(
      180deg,
      rgb(0, 0, 0) 11%,
      rgba(0, 0, 0, 0.3702731092) 56%,
      rgb(0, 0, 0) 95%
    ),
    url("../../Assets/images/Haunted_Mire.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.changelog_container_heading {
  font-size: 4rem;
  position: relative;
}

.changelog_container_heading::before {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 3px;
  background: linear-gradient(
    87deg,
    rgba(0, 0, 0, 0) 15%,
    rgb(183, 145, 85) 50%,
    rgba(0, 0, 0, 0) 85%
  );
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.changelogs {
  background: #000000a4;
  margin-top: 4rem;
  padding: 2rem;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.changelog {
  display: flex;
  flex-direction: column;
}
.changelog_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}
.changelog_title p {
  font-size: 2rem;
  color: #b79155;
  position: relative;
  font-family: var(--font), serif;
}
.changelog_title p::before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 100%;
  right: -10px;
  height: 2px;
  background: rgba(255, 255, 255, 0.166);
  transform: skew(120deg);
}
.changelog_title span {
  font-size: 1.2rem;
  color: #ffffff9a;

  font-style: italic;
}

.changelog_desc p {
  font-size: 1.4rem;
  color: #ffffffc7;
  font-weight: 300;
  letter-spacing: 1px;
  padding-right: 6rem;
  line-break: anywhere;
}

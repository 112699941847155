.hero_container {
  min-height: calc(100vh - 10rem);
  width: 100%;
  padding: 2rem;
  background-image: linear-gradient(
      180deg,
      rgb(0, 0, 0) 11%,
      rgba(0, 0, 0, 0.3702731092) 56%,
      rgb(0, 0, 0) 95%
    ),
    url("../../Assets/images/Haunted_Mire.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero_ability {
  height: calc(100vh - 10rem);
  width: 100%;
  display: flex;
  justify-content: center;
}
.hero_ability_left {
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.hero_ability_left_name {
  font-size: 4rem;
}
.hero_ability_left_ability {
  text-transform: uppercase;
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  max-width: 400px;
}
.hero_ability_left_desc {
  color: rgba(255, 255, 255, 0.5019607843);
  font-size: 1.2rem;
  text-align: center;
  max-width: 400px;
}
.hero_ability_left_abilities_details {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}
.hero_ability_left_abilities_details_field {
  display: flex;
  padding: 0px 15px;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.092);
  border-radius: 0px;
  transform: skew(10deg);
  position: relative;
  width: 250px;
}
.hero_ability_left_abilities_details_field img {
  transform: skew(-10deg);
  width: 40px;
  height: 40px;
}
.hero_ability_left_abilities_details_field p {
  font-size: 2rem;
  font-family: var(--font), serif;
  color: #b79155;
  transform: skew(-10deg);
}
.hero_ability_left_abilities_details_field span {
  font-size: 2.5rem;
  font-family: var(--font), serif;
  color: #fff;
  transform: skew(-10deg);
  margin-top: -5px;
}
.hero_ability_right {
  width: 50%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
}
.hero_ability_right video {
  position: absolute;
  height: 100%;
  /* width: 100%; */
  -o-object-fit: contain;
  object-fit: contain;
  top: 0;
  left: 0;
  display: block;
  /* transform: scale(2); */
}
.hero_ability_right img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.abilities_grid {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.abilities_grid_heading {
  font-size: 3rem;
  position: relative;
}
.abilities_grid_heading::before {
  content: "";
  position: absolute;
  bottom: 0px;
  height: 3px;
  background: linear-gradient(
    87deg,
    rgba(0, 0, 0, 0) 15%,
    rgb(183, 145, 85) 50%,
    rgba(0, 0, 0, 0) 85%
  );
  width: 100%;
  left: 0;
}
.abilities_grid_item {
  width: 100px;
  height: 100px;
  border-radius: 500px;
  position: relative;
}
.abilities_grid_item:hover .abilities_grid_item_details {
  height: -moz-max-content;
  height: max-content;
}
.abilities_grid_item_img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 500px;
}
.abilities_grid_item_details {
  background: #000;
  border-radius: 0px;
  width: 500px;
  height: 0;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  bottom: 150px;
  position: absolute;
  z-index: 99;
  transition: 0.1s ease-in;
  transform-origin: bottom;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 10px #111;
}
.abilities_grid_item_details_video {
  height: 300px;
  position: relative;
}
.abilities_grid_item_details_video video {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
  display: block;
}
.abilities_grid_item_details_details {
  background: #000;
  padding: 20px;
  height: 30%;
  position: relative;
}
.abilities_grid_item_details_details_name {
  font-size: 2rem;
  color: #fff;
  font-weight: 600;
}
.abilities_grid_item_details_details_desc {
  font-size: 1.2rem;
  color: #fff;
}
.abilities_grid_container {
  display: flex;
  margin-top: 10px;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.abilities_grid_container_horizontal {
  display: flex;
  margin-top: 10px;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.video_container {
  margin-top: 10rem;
  background: #000000;
  display: flex;
  border-radius: 0px;
  padding: 0 !important;
  overflow: hidden;
  height: 500px;
}
.video_container_video {
  width: 70%;
  position: relative;
}
.video_container_video video {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
  display: block;
}
.video_container_details {
  width: 30%;
  padding: 20px;
}
.video_container_details p {
  font-size: 5rem;
  line-height: 45px;
  color: #b79155;
  font-weight: 600;
  margin-bottom: 20px !important;
  position: relative;
}
.video_container_details span {
  font-size: 1.4rem;
  color: #fff;
} /*# sourceMappingURL=hero.css.map */

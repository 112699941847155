.about_container {
  min-height: calc(100vh - 10rem);
  padding: 2rem;
  padding-top: 5rem;
  background-image: linear-gradient(
      180deg,
      rgb(0, 0, 0) 11%,
      rgba(0, 0, 0, 0.3702731092) 56%,
      rgb(0, 0, 0) 95%
    ),
    url("../../Assets/images/Haunted_Mire.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about_container_heading {
  font-size: 4rem;
}
.about_container_span {
  color: #efe2cd;
  font-size: 1.6rem;
  max-width: 900px;
  text-align: center;
  position: relative;
}
.about_container_span::before {
  content: "";
  position: absolute;
  bottom: -20px;
  height: 3px;
  background: linear-gradient(
    87deg,
    rgba(0, 0, 0, 0) 15%,
    rgb(183, 145, 85) 50%,
    rgba(0, 0, 0, 0) 85%
  );
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

.about_image {
  width: 100%;
  height: 450px;
  margin: 6rem 0;
  border-radius: 0px;
  overflow: hidden;
  border: 2px solid #b79155;
  padding: 0 !important;
}
.about_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about_text_heading {
  color: #b79155;
  font-size: 4rem;
  font-family: var(--font), serif;
  position: relative;
  margin-bottom: 10px !important;
}
.about_text_heading::before {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 50%;
  left: -2px;
  height: 2px;
  background: linear-gradient(to top, #b79155 40%, #efe2cd);
  transform: skew(120deg);
}
.about_text_span {
  font-size: 1.4rem;
  color: #ffffff9a;
  position: relative;
  letter-spacing: 1px;
}
.about_text_span::before {
  content: "";
  position: absolute;
  bottom: -30px;
  width: 40%;
  right: 0;
  height: 2px;
  background: white;
  transform: skew(120deg);
}

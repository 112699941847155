.heroes {
  min-height: calc(100vh - 10rem);
  padding: 2rem;
  padding-top: 5rem;
  background-image: linear-gradient(
      180deg,
      rgb(0, 0, 0) 11%,
      rgba(0, 0, 0, 0.3702731092) 56%,
      rgb(0, 0, 0) 95%
    ),
    url("../../Assets/images/Haunted_Mire.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heroes_heading_container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  position: relative;
}
.heroes_heading_container::before {
  content: "";
  position: absolute;
  bottom: -20px;
  height: 3px;
  background: linear-gradient(
    87deg,
    rgba(0, 0, 0, 0) 15%,
    rgb(183, 145, 85) 50%,
    rgba(0, 0, 0, 0) 85%
  );
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

.heroes_heading_image {
  max-width: 40rem;
  max-height: 40rem;
}
.heroes_heading_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.heroes_heading {
  max-width: 60rem;
}
.heroes_heading p {
  font-size: 4rem;
}
.heroes_heading span {
  color: #efe2cd;
  font-size: 1.6rem;
  max-width: 900px;
  text-align: center;
  position: relative;
}

.search {
  margin-top: 5rem;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.search_wrapper {
  background-color: rgba(255, 255, 255, 0.37);
  height: 100%;
  width: 30rem;
  display: flex;
  align-items: center;
  border-radius: 0px;
}
.search_wrapper i {
  font-size: 2rem;
  padding: 10px;
  color: #fff;
  margin-top: 2px;
}
.search_wrapper input {
  flex: 1;
  height: 100%;
  outline: none;
  border: none;
  background: none;
  font-size: 1.2rem;
  color: #fff;
}
.search_wrapper input::placeholder {
  font-size: 1.2rem;
  color: #fff;
}

.heroes_container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  margin-top: 2rem;
}

.hero {
  /* background: black; */
  border-radius: 25px;
  aspect-ratio: 1/1.5;
  position: relative;
}
.hero_img {
  position: absolute;
  inset: 0;
  border-radius: 25px;
  overflow: hidden;
  /* border: 2px solid #b79155; */
  transition: transform 0.1s ease-in-out;
}
.hero_img:hover {
  transform: scale(1.2);
  z-index: 99;
}
.hero_img:hover .hero_details {
  opacity: 1;
}
.hero_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero_details {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9164915966) 20%,
    rgba(68, 166, 255, 0.0509453782) 100%
  );
  padding: 1vw;
  opacity: 0;
  transition: transform 0.1s ease-in-out;
}
.hero_details p {
  text-transform: capitalize;
  font-size: 1.3vw;
  line-height: 1.5vw;
  margin-bottom: 10px !important;
  line-break: anywhere;
}
.hero_details span {
  font-size: 1.4rem;
  color: #fff;
}

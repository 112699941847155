@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Inknut+Antiqua:wght@400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  font-size: 62.5%;
  --font: "Inknut Antiqua";
}

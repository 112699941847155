html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: url('../../Assets/images/background-image.jpg') no-repeat center center fixed;
  background-size: cover;
  color: #fff;
  overflow-x: hidden; /* Prevent horizontal scroll */
  display: flex;
  flex-direction: column;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container-rpc {
  flex: 1; /* Take up all available space */
  display: flex;
  flex-direction: column;
  position: relative; /* Added for absolute positioning of the character image */
}

.content-rpc {
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  align-items: center; /* Center vertically */
  flex-grow: 1; /* Ensure the content takes all available space */
  max-width: 1200px;
  position: relative; /* Ensure it contains absolutely positioned children */
  margin-bottom: 80px;
}

.left-side {
  margin-left: 80px; /* Fixed distance from the left side of the screen */
  width: 40%; /* Set the width to 40% of the content */
  text-align: center; /* Center align text within the left column */
  z-index: 2; /* Ensure it stays above the character image */
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add gap between elements */
  align-items: center; /* Center align items horizontally */
}

.logo {
  max-width: 400px;
  margin-top: 60px;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  background: url('../../Assets/images/video_bg.png') no-repeat center center;
  background-size: cover;
  overflow: hidden; /* Ensure the video doesn't overflow */
}

.video-container video {
  width: calc(100% - 8px); /* Slightly reduce the width to fit within the frame */
  height: calc(100% - 8px); /* Slightly reduce the height to fit within the frame */
  object-fit: cover; /* Cover the container while preserving aspect ratio */
  display: block;
  margin: 4px; /* Center the video within the container */
}

.video-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Allow interactions with the video */
  border: none;
}

.early-access-text {
  max-width: 100%; /* Ensure the image fits within the container */
  height: auto;
  font-size: 56px;
}

.buttons {
  display: flex;
  gap: 10px;
}

.button {
  width: 160px; /* Set a fixed width for the buttons */
  height: 50px; /* Set a fixed height for the buttons */
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  text-indent: -9999px; /* Hide the text */
}

.button.discord {
  background-image: url('../../Assets/images/Btn_discord_nor.png');
}

.button.discord:hover {
  background-image: url('../../Assets/images/Btn_discord_hover.png');
}

.button.discord:active {
  background-image: url('../../Assets/images/Btn_discord_press.png');
}

.button.steam {
  background-image: url('../../Assets/images/Btn_steam_nor.png');
}

.button.steam:hover {
  background-image: url('../../Assets/images/Btn_steam_hover.png');
}

.button.steam:active {
  background-image: url('../../Assets/images/Btn_steam_press.png');
}

.right-side {
  position: absolute; /* Position it absolutely within the container */
  top: 0;
  right: 0;
  bottom: 0;
  width: 60%; /* Increase the width to cover more of the screen */
  height: 100%; /* Ensure it covers the full height */
  overflow: hidden; /* Ensure it doesn't overflow */
  z-index: 1; /* Ensure it's below the left-side content */
}

.character-img {
  position: fixed;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  max-height: 100vh; /* Ensure the image doesn't exceed the viewport height */
  object-fit: cover; /* Cover the container while preserving aspect ratio */
  object-position: right center; /* Align the image to the right */
  z-index: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.character-img.fade-in {
  opacity: 1;
}

.character-img.fade-out {
  opacity: 0;
}

@media (max-width: 1124px) {
  .content-rpc {
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .left-side, .right-side {
    margin: 0;
    position: relative; /* Revert to relative positioning on smaller screens */
    width: 100%;
  }

  .right-side {
    text-align: center;
    align-items: center;
  }

  .left-side {
    padding: 10px;
  }

  .left-side, .character-img {
    max-width: 90%;
  }

  .right-side, .character-img {
    text-align: center;
  }

  .character-img {
    width: 100%;
    height: auto;
    object-position: center top;
    bottom: 0;
  }
}

/* Additional media query for larger screens */
@media (min-width: 1600px) {
  .left-side {
    width: 50%; /* Increase the width for larger screens */
  }

  .logo {
    max-width: 600px; /* Increase logo size */
  }

  .video-container {
    max-width: 600px; /* Increase video container size */
  }

  .button {
    width: 200px; /* Increase button size */
    height: 60px;
  }
}

/* Footer styles */
.footer {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 0;
  width: 100%;
  flex-shrink: 0; /* Ensure the footer does not shrink */
  z-index: 3; /* Ensure it stays on top of the character image */
  position: fixed;
  bottom: 0;
}

.footer .social_icons_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.footer .social_icons_footer .social_icons_icons {
  display: flex;
  gap: 15px;
}

.footer .social_icons_footer .social_icons_icons a {
  color: #fff;
  font-size: 1.5em;
}

.footer .copyrights {
  text-align: center;
}

.footer .copyrights img {
  width: 80px;
  object-fit: contain;
  margin-bottom: 10px;
}

/* Fade-in and fade-out animation */
.fade-in {
  animation: fadeIn 1s forwards;
}

.fade-out {
  animation: fadeOut 1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
